@import "./abstract/variables";
@import "./theme/fonts";
@import "./theme/font-gilroy";
@import "./theme/bootstrap.scss";

* {
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    background-color: #F6F7F8;

    font-family: 'Gilroy', sans-serif !important;
    font-size: 0.9rem !important;


}

.avatar {
    height: 45px;
    width: 45px;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
}

.nowrap {
    white-space: nowrap;
}
$primary-color: #04619E;
$secondary-color: #72BE46;

//$surface-dark: #2A3142;
$surface-dark: #191B1F;
$surface-light: #ffffff;
$text-light: #FFFFFF;

//z-indexes
$lowest: 1;
$lower: 2;
$low: 3;
$top: 4;
$topper: 5;
$topest: 6;
